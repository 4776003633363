import React, {useEffect, useState} from "react";
import {hasPermission,} from "../../../utils/Authentication";
import {useDispatch, useSelector} from "react-redux";
import {ACTUATOR_TYPE, IRRIGATION_STATUS} from "../../../utils/Enum";
import axios from "axios";
import FeatherIcon from "feather-icons-react";
import {toggleLoader} from "../../../shared/actions/setting";
import {toast} from "react-toastify";
import {getMQTTStatusPlot, getPlotIrrigationStatus} from "../../../actions/plot";
import {useTranslation} from "react-i18next";
import {getUserId, getUsername} from "../../../shared/utils/SharedAuthentication";
import {dateFormat, isEmpty} from "../../../shared/utils/utils";
import {onValue, ref} from "firebase/database";
import {database} from "../../../utils/firebaseConfig";
import PlotIrrigationStatus from "./plot-irrigation-status";
import {getFarmSource} from "../../../actions/farm";
import ManualIrrigationPopup from "./manual-irrigation-popup";


const PlotIrrigationManual = (props) => {
  const [plotManualIrrigation, setPlotManualIrrigation] = useState([]);
  const [sourceKitProperties, setSourceKitProperties] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [powerStatus, setPowerStatus] = useState(true);
  const [currentSensorStatus, setCurrentSensorStatus] = useState(false);
  const [status, setStatus] = useState(null);
  const [duration, setDuration] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [irrigationTime, setIrrigationTime] = useState("");
  const [wateringTime, setWateringTime] = useState(null);
  const [dateRange, setDataRange] = useState({
    fromDate: dateFormat(new Date(Date.now() - 604800000)),
    toDate: dateFormat(new Date()),
  });
  const [isTable, setTable] = useState(false);
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();
  const farmSource = useSelector(state => {
    return state.farmList.farmSource;
  });

  const plotMqttStatus = useSelector(state => {
    return state.plotList.plotMqtt;
  });

  const plotIrrigationStatus = useSelector(state => {
    return state.plotList.plotIrrigationStatus
  });

  useEffect(() => {
    if (isEmpty(sourceKitProperties) || isEmpty(farmSource)) return;
  
    sourceKitProperties.forEach(prop => {
      if (prop.code === "CS" && prop.number === farmSource.actuatorNumber) {
        setCurrentSensorStatus(prop.value === 1);
      }}
    );
  }, [sourceKitProperties]);


  // useEffect(() => {
  //   dispatch(toggleLoader(true));
  //   axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/irrigation/` + props.irrigationId + `/status`)
  //     .then(function (response) {
  //       if (response.data.content) {
  //         dispatch(getPlotIrrigationStatus(response.data.content));
  //       }
  //     })
  //     .catch(function (error) {
  //     })
  //     .finally(() => {
  //       dispatch(toggleLoader(false));
  //     });
  //
  // }, [props.irrigationId]);

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/irrigation/` + props.irrigationId + `/watering`)
      .then(function (response) {
        if (response.data.content) {
          setWateringTime(response.data.content)
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message)
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });

  }, [props.irrigationId]);

  useEffect(() => {
    if (!props.farmId && !props.irrigationId) {
      return
    }

    const query = ref(database, 'irrigation/' + props.farmId + '/' + props.irrigationId);
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      console.log('FB:ref=> irrigation/' + props.farmId + '/' + props.irrigationId + " data=>", data);  //dont delete this log
      if (snapshot.exists()) {
        dispatch(getPlotIrrigationStatus(data));
      }
    });
  }, [props.farmId, props.irrigationId]);

  useEffect(() => {
    if (!farmSource.id) {
      return
    }

    const query = ref(database, 'valves/' + props.farmId + '/' + farmSource.id);
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      console.log('FB:ref=> valves/' + props.farmId + '/' + farmSource.id + " data=>", data);  //dont delete this log
      if (snapshot.exists()) {
        dispatch(getFarmSource(data));
      }
    });
  }, [farmSource.id]);

  useEffect(() => {
    if (!farmSource.kitId) {
      return
    }

    const query = ref(database, 'kits/' + farmSource.kitId + '/');
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      console.log('FB:ref=> kits/' + farmSource.kitId + " data=>", data);  //dont delete this log

      if (snapshot.exists()) {
        setSourceKitProperties(data);
      }
    });
  }, [farmSource.kitId]);

  useEffect(() => {
    if (!props.plotId) {
      return
    }

    const query = ref(database, 'kitPowerStatus/' + props.plotId + '/');
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      console.log('FB:ref=> kitPowerStatus/' + props.plotId + " data=>", data);  //dont delete this log

      if (snapshot.exists()) {
        setPowerStatus(data)
      }
    });
  }, [props.plotId]);

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    dispatch(toggleLoader(true));
    axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/irrigation/` + props.irrigationId + '/status/' + status + '?duration=' + irrigationTime)
      .then(function (response) {
        let history = {};
        history.user = {};
        history.user.username = getUsername();
        history.actionAt = new Date().toISOString().substring(0, 10) + " " + new Date().toString().substring(16, 24);
        console.log(status)
        props.updateData();
        if (status === IRRIGATION_STATUS.ON) {
          props.irrigation.status = IRRIGATION_STATUS.ON_REQUESTED;
          history.action = "MANUAL_MODE_IRRIGATION_ON";
        } else if (status === IRRIGATION_STATUS.OFF) {
          props.irrigation.status = IRRIGATION_STATUS.OFF_REQUESTED;
          history.action = "MANUAL_MODE_IRRIGATION_OFF";
        }
        let date = dateRange;
        setDataRange(null);
        setDataRange(date);
        setPlotManualIrrigation(values => [history, ...values]);
        getIrrigationStatus();
        toast.success("Requested to " + status + " irrigation for this plot");
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        } else {
          toast.error(t("errorMsg.SOMETHING_WENT_WRONG"));
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
        setIsLoading(false);
      });
  }, [isLoading]);

  function getIrrigationStatus() {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/irrigation/` + props.irrigationId + `/status`)
      .then(function (response) {
        if (response.data.content) {
          dispatch(getPlotIrrigationStatus(response.data.content));
        }
      })
      .catch(function (error) {
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }


  useEffect(() => {
    if (!dateRange) {
      return;
    }
    dispatch(toggleLoader(true));
    let fromDate = dateRange ? dateRange.fromDate : dateFormat(new Date(Date.now() - 604800000));
    let toDate = dateRange ? dateRange.toDate : dateFormat(new Date());
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + '/irrigation/' + props.irrigationId + '/manual' + '/history' + '?from=' + fromDate + '&to=' + toDate)
      .then(function (response) {
        setPlotManualIrrigation(response.data.content);
        setIsLoading(false);
        // setDataRange(null);goog
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
        }
      }).finally(() => {
      dispatch(toggleLoader(false));
    })
  }, [dateRange, props.irrigationId]);

  useEffect(() => {
    if (!plotMqttStatus) {
      return
    }
    if (plotMqttStatus.plotId === props.plotId) {
      props.irrigation.status = plotMqttStatus.status;
      dispatch(getMQTTStatusPlot(null));
    }
  }, [plotMqttStatus]);

  function getStatusText() {
    switch (props.irrigation.status) {
      case IRRIGATION_STATUS.ON :
        return "WATERING";
      case IRRIGATION_STATUS.ON_REQUESTED :
        return "WAITING FOR WATERING";
      case IRRIGATION_STATUS.PENDING :
        return "WAITING FOR POWER";
      case IRRIGATION_STATUS.OFF :
        return "IDLE";
      case IRRIGATION_STATUS.OFF_REQUESTED :
        return "WAITING FOR WATERING TO STOP";
      case IRRIGATION_STATUS.ERROR :
        return "ERROR OCCURRED";
    }
  }

  function onCheck(e) {
    const dates = {};
    dates.fromDate = dateFormat(e[0]);
    dates.toDate = dateFormat(e[1]);
    setDataRange(null);
    setDataRange(dates);

  }

  function checkIrrigationOff() {
    return ([IRRIGATION_STATUS.OFF, IRRIGATION_STATUS.OFF_REQUESTED].includes(plotIrrigationStatus.status) || !plotIrrigationStatus.status)
  }

  function manualIrrigationConfirmation() {
    // dispatch(toggleConfirmationDialog({
    //   isVisible: true,
    //   type: 'request',
    //   showDurationField: ((!plotIrrigationStatus.status || plotIrrigationStatus.status === IRRIGATION_STATUS.OFF) ? true : false),
    //   confirmationHeading: ((!plotIrrigationStatus.status || plotIrrigationStatus.status === IRRIGATION_STATUS.OFF) ? t("confirmation_msg.ARE_YOU_SURE_TO_TURN_ON") : t("confirmation_msg.ARE_YOU_SURE_TO_TURN_OFF")),
    //   confirmationDescription: ((!plotIrrigationStatus.status || plotIrrigationStatus.status === IRRIGATION_STATUS.OFF) ? t("confirmation_msg.THE_ACTION_WILL_TURN_ON_IRRIGATION") : t("confirmation_msg.THE_ACTION_WILL_TURN_OFF_IRRIGATION"))
    // }));
    setIsVisible(true)
  }

  function handleConfirmationSubmit(data) {
    setIsVisible(false)

    if (data && data.onSuccess) {
      if (checkIrrigationOff()) {
        setStatus('ON');
        setIrrigationTime(data.duration || '')
        setIsLoading(true);
      } else if ([IRRIGATION_STATUS.ON, IRRIGATION_STATUS.PENDING, IRRIGATION_STATUS.ON_REQUESTED].includes(plotIrrigationStatus.status)) {
        setStatus('OFF');
        setIrrigationTime("")
        setIsLoading(true);
      }
    }
  };

  function manualIrrigationDisabled() {
    return false;
    // return (
    //   plotIrrigationStatus.status === IRRIGATION_STATUS.ON_REQUESTED ||
    //   plotIrrigationStatus.status === IRRIGATION_STATUS.OFF_REQUESTED
    // );
  }

  useEffect(() => {
    if (!isTable) {
      return
    }
    let elem = document.getElementById("scroll");
    if (elem) {
      elem.scrollIntoView();
    }
  }, [isTable]);

  useEffect(() => {
    getIrrigationStatus()
  }, [props.irrigationId]);

  useEffect(() => {
    const calculateDuration = () => {
      if (plotIrrigationStatus.irrigationStartTime) {
        const currentTime = new Date();
        // Extracting only the time part from irrigationStartTime
        const irrigationStartTime = new Date(plotIrrigationStatus.irrigationStartTime);
        const irrigationStartTimeHours = irrigationStartTime.getHours();
        const irrigationStartTimeMinutes = irrigationStartTime.getMinutes();
        // Creating a new Date object with the current date and extracted time
        const irrigationStartDate = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate(), irrigationStartTimeHours, irrigationStartTimeMinutes);
        const durationInMinutes = Math.round((currentTime - irrigationStartDate) / (1000 * 60));
        setDuration(durationInMinutes);
      }
    };

    calculateDuration();

    const intervalId = setInterval(() => {
      calculateDuration();
    }, 60000); 

    return () => clearInterval(intervalId);
  }, [plotIrrigationStatus]);
  


  return (
    <div>
      <div className="text-center position-relative">
        {!powerStatus && <div className="grayout-overlay">
          <FeatherIcon className="grayout-icon" icon="alert-triangle" />
          <div className="grayout-message">{t("info.POWER_DOWN")}</div>
        </div> }
        {farmSource && farmSource.type === ACTUATOR_TYPE.MOTOR &&
          <div className={"ir-manual-ind"}>
            <div className={"ir-manual-ind-c left " + (currentSensorStatus ? "success" : "error")}>
              <FeatherIcon className={"ir-manual-icon"} icon="zap"/>
              <div className={"ir-manual-title"}>{t("title.POWER")}</div>
            </div>
            <div className={"ir-manual-ind-c right " + (farmSource.open ? "success" : "error")}>
              <FeatherIcon className={"ir-manual-icon"} icon="git-commit"/>
              <div className={"ir-manual-title"}>{t("title.MOTOR")}</div>
            </div>
            <div
              className={"ir-manual-ind-line"}> {plotIrrigationStatus && plotIrrigationStatus.description
              ? plotIrrigationStatus.description
              : "-"}
              {plotIrrigationStatus && plotIrrigationStatus.status === IRRIGATION_STATUS.ON_REQUESTED
                ? (wateringTime ? ` ${wateringTime}` : '')
                : ''}</div>
            <div className={"ir-manual-ind-line-text"}><FeatherIcon className={"ir-manual-icon-alert"}
                                                                    icon="alert-circle"/></div>
          </div>
        }
        {/*{farmSource && farmSource.type === ACTUATOR_TYPE.TANK && (*/}
        {/*  <div className={"ir-manual-ind"}>*/}
        {/*    <div className={"ir-manual-ind-line-tank"}>*/}
        {/*      {plotIrrigationStatus && plotIrrigationStatus.description*/}
        {/*        ? plotIrrigationStatus.description*/}
        {/*        : "-"}*/}
        {/*      /!*{plotIrrigationStatus && plotIrrigationStatus.status === IRRIGATION_STATUS.ON_REQUESTED*!/*/}
        {/*      /!*  ? (wateringTime ? ` ${wateringTime}` : '')*!/*/}
        {/*      /!*  : ''}*!/*/}
        {/*    </div>*/}
        {/*    <div className={"ir-manual-ind-line-text"}>*/}
        {/*      <FeatherIcon*/}
        {/*        className={"ir-manual-icon-alert"}*/}
        {/*        icon="alert-circle"*/}
        {/*        style={{marginTop: '10px'}}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}

        <PlotIrrigationStatus irrigationId={props.irrigationId} wateringTime={wateringTime}/>



        {plotIrrigationStatus.status === IRRIGATION_STATUS.ON && duration > 0 &&
          <div className="turn-off-text">{`Watering (${duration} mins).`}</div>
        }
        {plotIrrigationStatus.status === IRRIGATION_STATUS.ON && duration === 0 &&
          <div className="turn-off-text">{`Watering (0 mins).`}</div>
        }
        {hasPermission("WRITE") ?
          [IRRIGATION_STATUS.ON, IRRIGATION_STATUS.PENDING, IRRIGATION_STATUS.ON_REQUESTED].includes(plotIrrigationStatus.status) ?
            <button
              className={"btn btn-sa-danger w-140p m-b-7 m-t-25"}
              onClick={() => {
                manualIrrigationConfirmation();
              }} disabled={manualIrrigationDisabled()} style={{opacity: manualIrrigationDisabled() ? 0.5 : 1}}
            >{t("button.TURN_OFF")}
            </button>
            :
            <button className={"btn btn-sa-success w-140p m-b-7 m-t-25"}
                    onClick={() => {
                      manualIrrigationConfirmation();
                    }} disabled={manualIrrigationDisabled()} style={{opacity: manualIrrigationDisabled() ? 0.5 : 1}}
            >{t("button.TURN_ON")}
            </button> : " "
        }
      </div>

      {isVisible && 
        <ManualIrrigationPopup confirmationHeading={(checkIrrigationOff() ? t("confirmation_msg.ARE_YOU_SURE_TO_TURN_ON") : t("confirmation_msg.ARE_YOU_SURE_TO_TURN_OFF"))}
            confirmationDescription={(checkIrrigationOff() ? t("confirmation_msg.THE_ACTION_WILL_TURN_ON_IRRIGATION") : t("confirmation_msg.THE_ACTION_WILL_TURN_OFF_IRRIGATION"))}  
            onSubmit={handleConfirmationSubmit} showDurationField={(checkIrrigationOff() ? true : false)} /> }

      {/*{[IRRIGATION_STATUS.ON, IRRIGATION_STATUS.OFF_REQUESTED].includes(props.irrigation.status) ?*/}
      {/*  <div className="text-center">*/}
      {/*      <div className={"ir-manual-ind"}>*/}
      {/*          <div className={"ir-manual-ind-c left success"}>*/}
      {/*              <FeatherIcon className={"ir-manual-icon"} icon="zap"/>*/}
      {/*              <div className={"ir-manual-title"}>Power</div>*/}
      {/*          </div>*/}
      {/*          <div className={"ir-manual-ind-c right error"}>*/}
      {/*              <FeatherIcon className={"ir-manual-icon"} icon="git-commit"/>*/}
      {/*              <div className={"ir-manual-title"}>Motor</div>*/}
      {/*          </div>*/}
      {/*          <div className={"ir-manual-ind-line"}>Waiting for power</div>*/}
      {/*          <div className={"ir-manual-ind-line-text"}><FeatherIcon className={"ir-manual-icon-alert"} icon="alert-circle"/></div>*/}
      {/*      </div>*/}
      {/*    <button className={"btn btn-sa-danger w-140p m-b-7 "+(props.irrigation.status !== IRRIGATION_STATUS.ON?" no-cursor":"")}*/}
      {/*            onClick={() => {*/}
      {/*              setStatus('OFF');*/}
      {/*              setIsLoading(true);*/}
      {/*            }}*/}
      {/*            disabled={props.irrigation.status !== IRRIGATION_STATUS.ON}>TURN OFF*/}
      {/*    </button>*/}
      {/*    <div className="turn-off-text">Running Time : 23 Min</div>*/}
      {/*  </div>*/}
      {/*  :*/}
      {/*  <div className="text-center">*/}
      {/*      <div className={"ir-manual-ind"}>*/}
      {/*          <div className={"ir-manual-ind-c left success"}>*/}
      {/*              <FeatherIcon className={"ir-manual-icon"} icon="zap"/>*/}
      {/*              <div className={"ir-manual-title"}>Power</div>*/}
      {/*          </div>*/}
      {/*          <div className={"ir-manual-ind-c right error"}>*/}
      {/*              <FeatherIcon className={"ir-manual-icon"} icon="git-commit"/>*/}
      {/*              <div className={"ir-manual-title"}>Motor</div>*/}
      {/*          </div>*/}
      {/*          <div className={"ir-manual-ind-line"}>Waiting for power</div>*/}
      {/*          <div className={"ir-manual-ind-line-text"}><FeatherIcon className={"ir-manual-icon-alert"} icon="alert-circle"/></div>*/}
      {/*      </div>*/}
      {/*    <button className={"btn btn-sa-success w-140p m-b-7 "+(props.irrigation.status !== IRRIGATION_STATUS.OFF?" no-cursor":"")}*/}
      {/*            onClick={() => {*/}
      {/*              setStatus('ON');*/}
      {/*              setIsLoading(true);*/}
      {/*            }}*/}
      {/*            disabled={props.irrigation.status !== IRRIGATION_STATUS.OFF}>TURN ON*/}
      {/*    </button>*/}
      {/*    <div className="turn-off-text">Last turned on : 43 Min ago</div>*/}
      {/*  </div>*/}
      {/*}*/}
      {/*<div style={{marginLeft: "0%", width: isTable ? "78%" : '100%'}}>*/}
      {/*  <div style={{marginTop: "40%", marginLeft: "-245%", width: "410%"}}>*/}

      {/*    <div className={"table-container-toggle " + (isTable ? "" : "table-toggle-hide")}>*/}
      {/*      <hr className="table-toggle-line"/>*/}
      {/*      <div className="table-toggle-heading">{t("title.MANUAL_MODE_CHANGE_HISTORY")}</div>*/}
      {/*      <div onClick={() => setTable(!isTable)} className="table-dropdown-icon"><i*/}
      {/*        className={"fa " + (isTable ? "fa-angle-up" : "fa-angle-down")}/></div>*/}
      {/*    </div>*/}

      {/*    <div id={"scroll"} className={(isTable ? "" : "hide-table")}>*/}
      {/*      <div className={"plot-report-filter-box"}>*/}
      {/*        <div className={"plot-report-filter m-t-26"}>*/}
      {/*          <DateRangePicker disabledDate={date => date > new Date()} disabled={false} onChange={(e) => onCheck(e)}*/}
      {/*                           oneTap={false} onOk={(e) => onCheck(e)}*/}
      {/*                           showWeekNumbers={true} appearance={"default"} placeholder={t("title.LAST_WEEK")}*/}
      {/*                           ranges={[{*/}
      {/*                             label: 'Today',*/}
      {/*                             value: [new Date(), new Date()]*/}
      {/*                           }, {*/}
      {/*                             label: 'Yesterday',*/}
      {/*                             value: [dateFns.addDays(new Date(), -1), dateFns.addDays(new Date(), -1)]*/}
      {/*                           }, {*/}
      {/*                             label: 'Last 7 days',*/}
      {/*                             value: [dateFns.subDays(new Date(), 6), new Date()]*/}
      {/*                           }, {*/}
      {/*                             label: 'Last 30 days',*/}
      {/*                             value: [dateFns.subDays(new Date(), 30), new Date()]*/}
      {/*                           }]}*/}
      {/*          />*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className={"sa-table-container "}>*/}
      {/*        <table className="table table-borderless sa-table-width">*/}
      {/*          <thead>*/}
      {/*          <tr>*/}
      {/*            <th className={"sa-table-head-sticky"}> {t("title.USER")}</th>*/}
      {/*            <th className={"sa-table-head-sticky"}> {t("title.ACTION")}</th>*/}
      {/*            <th className={"sa-table-head-sticky"}> {t("title.DATE_AND_TIME")}</th>*/}
      {/*          </tr>*/}
      {/*          </thead>*/}
      {/*          <tbody>*/}
      {/*          {plotManualIrrigation.length > 0 && (plotManualIrrigation.map((irrigation, index) =>*/}
      {/*            <tr key={index}>*/}
      {/*              <td className={'sa-table-data'}>{irrigation.user ? irrigation.user.username : 'Unknown'}</td>*/}
      {/*              <td className={'sa-table-data'}>*/}
      {/*                <div*/}
      {/*                  className="table-status-active w-90">{irrigation.action === "MANUAL_MODE_IRRIGATION_OFF" ? "OFF" : "ON"}</div>*/}
      {/*              </td>*/}
      {/*              <td className={'sa-table-data'}>{formatDisplayEnumValue(irrigation.actionAt)}</td>*/}
      {/*            </tr>*/}
      {/*          ))}*/}
      {/*          </tbody>*/}
      {/*        </table>*/}
      {/*        {plotManualIrrigation.length === 0 && (*/}
      {/*          <div className={"empty-results"}>*/}
      {/*            <FeatherIcon icon="info"/>*/}
      {/*            <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_MANUAL_IRRIGATION_HISTORIES")}</div>*/}
      {/*          </div>)*/}
      {/*        }*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*  </div>*/}
      {/*</div>*/}

    </div>
  );

};

export default PlotIrrigationManual;
