import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import {DatePicker} from "rsuite";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {minsToDaysHoursMins} from "../../../utils/Utils";
import {useParams} from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import * as _ from "underscore";
import 'c3/c3.css';
import {css} from "@emotion/core";
import FarmIrrigationGraph from "./farm-irrigation-graph";
import ToggleLayout from "../../toggle-layout";
import irrigationIcon from "../../../images/fi_list.svg";
import FarmAlertHistory from "./farm-alert-history";
import {useTranslation} from "react-i18next";
import {getUserId} from "../../../shared/utils/SharedAuthentication";

const FarmReport = () => {
  const {t, i18n} = useTranslation();

  const plotList = useSelector(state => {
    return state.plotList.plotList
  });
  const dispatch = useDispatch();
  const [farmDetails, setFarmDetails] = useState([]);
  const [aggregatedHistory, setAggregatedHistory] = useState([]);
  const [fromDate, setFromDate] = useState();
  const [annual, setAnnual] = useState();
  const [isGraph, setIsGraph] = useState(false);
  const [isPeriod, setIsPeriod] = useState(false);
  const {farmId} = useParams();
  const [dataSet, setDataSet] = useState({});
  const [farmReport, setFarmReport] = useState(false);
  const [visibleToggleIndex, setVisibleToggleIndex] = useState(1);
  let elements = document.getElementsByClassName("rs-calendar-month-dropdown-year");
  const override = css`
    display: block;
    margin: 0 auto;
    padding-bottom: 150px;
    padding-top: 150px;
    border-color: red;
    width: fit-content;
  `;

  function getReferences(plotId) {
    let plot = _.findWhere(plotList, {'id': plotId});
    return !plot ? plotId : plot.name;
  }

  //
  // async function addDataGraphDate(graphData) {
  //   await new Promise((resolve, reject) => {
  //     resolve(1), setDataSet(graphData)
  //   });
  // }

  //
  useEffect(() => {
    if (!fromDate) {
      return
    }

    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/irrigation/summary' + '?from=' + fromDate + '-01')
      .then(function (response) {
        setFarmDetails(response.data.content);
      })
      .catch(function (error) {

      })
      .finally(() => {
      })
  }, [fromDate]);

  // useEffect(() => {
  //   if (!fromDate) {
  //     return
  //   }
  // dispatch(toggleLoader(true));
  //   axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/irrigation/aggregated-history' + '?date=' + (isPeriod ? (annual + '-01-01') : (fromDate + '-01')) + '&type=' + (isPeriod ? 'ANNUAL' : 'MONTHLY'))
  //     .then(function (response) {
  //
  //       setLoadGraph(false);
  //       setAggregatedHistory(response.data.content);
  //     })
  //     .catch(function (error) {
  //     })
  //     .finally(() => {
  //       dispatch(toggleLoader(false));
  //     })
  // }, [fromDate, isPeriod]);

  //
  // useEffect(() => {
  //   if (dataSet.length) {
  //     dataSet.slice(0, dataSet.length);
  //   }
  //   if (farmDetails.length == 0) {
  //     return;
  //   }
  //   setLoadGraph(false);
  //   drawGraph();
  // }, [isGraph, isPeriod, aggregatedHistory]);


  useEffect(() => {
    var date = new Date(),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    setFromDate([date.getFullYear(), month].join("-"));
    setAnnual([date.getFullYear()]);


  }, [])


  // function styleGraph() {
  //   if (window.innerWidth < 769) {
  //
  //     d3.select(".c3-axis-x-label").attr("dy", "42px");
  //     d3.selectAll(".tick").style("font-size", "7px");
  //     d3.select(".c3-axis-y-label").attr("dy", "-34px");
  //   } else {
  //     d3.select(".c3-axis-y-label").attr("dy", "-36px");
  //   }
  //   d3.selectAll(".c3-legend-item").attr("x", "400");
  //
  // }


  function onCheck(e) {
    var date = new Date(e),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    setFromDate([date.getFullYear(), month].join("-"));

    setAnnual([date.getFullYear()]);

  }

  // function drawGraph() {
  //
  //   const durationCurrentAggregated = _.pluck(aggregatedHistory, (isPeriod ? 'longDuration' : 'duration'));
  //   const date = _.pluck(aggregatedHistory, 'date');
  //   const dateSliced = [];
  //   date.map(d => {
  //     dateSliced.push(d.slice(0, 10));
  //   });
  //   const graphData = {};
  //   const tooltip = {
  //     format: {
  //       value: function (value, ratio, id, index) {
  //         return minsToDaysHoursMins(value);
  //       }
  //     },
  //   };
  //   const data = {
  //     xs: {
  //       'Duration': 'Date',
  //     },
  //     xFormat: '%Y-%m-%d',
  //     columns: [
  //       ['Duration'].concat(durationCurrentAggregated),
  //       ['Date'].concat(dateSliced),
  //     ],
  //     types: {},
  //   };
  //   const axis = {
  //     x: {
  //       show: true,
  //       type: 'timeseries',
  //       tick: {
  //         multiline: true,
  //         format: isPeriod ? '%Y-%m' : '%Y-%m-%d',
  //         count: isPeriod ? 12 : 31,
  //         fit: true,
  //         rotate: window.innerWidth > 767 ? 0 : 9,
  //         culling: {
  //           max: window.innerWidth > 767 ? 6 : 5,
  //         },
  //         height: 100,
  //
  //
  //       },
  //       label: {
  //         text: 'Date Time',
  //         position: 'outer-center',
  //       },
  //
  //     },
  //     y: {
  //       label: {
  //         text: 'Duration(min)',
  //         position: 'outer-middle',
  //
  //       }
  //     },
  //   };
  //   const zoom = {
  //     rescale: true
  //
  //   };
  //
  //   graphData['data'] = data;
  //   graphData['axis'] = axis;
  //   graphData['tooltip'] = tooltip;
  //   graphData['zoom'] = zoom;
  //   addDataGraphDate(graphData).then(() => {
  //     setLoadGraph(true);
  //
  //   });
  // }
  return (
    <Container>
      <ToggleLayout visibleToggleIndex={visibleToggleIndex}
                    onVisibleToggleIndexChange={(index) => setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}
                    image={irrigationIcon} title={t("title.IRRIGATION_SUMMARY")} Farm={false} dropDown={false}
                    toggleIndex={1}>
        <div>
          <span className={"sa-table-float-left"}>
            <DatePicker format={isGraph && isPeriod ? window.innerWidth < 360 ? "YYYY-MM" : "YYYY/YYYY-MM" : "YYYY-MM"}
                        appearance={"default"} placeholder={"Select Period"}
                        defaultValue={new Date()}
                        onChange={(e) => onCheck(e)}
                        onOk={(e) => onCheck(e)}
                        ranges={[]}/>
          </span>
          <button type="submit" className="graph-report-btn pull-right "
                  onClick={() => {
                    setIsGraph(!isGraph);
                    setFarmReport(true)
                  }}><span><FeatherIcon className="trend-icon" size="24"
                                        icon={isGraph ? 'credit-card' : 'trending-up'}/></span><label
            className="toggle-table">{isGraph ? t("title.TABLE") : t("title.GRAPH")} View</label>
          </button>
          {isGraph && <button type="submit" className="graph-report-btn2"
                              onClick={() => setIsPeriod(!isPeriod)}> {isPeriod ? 'MONTHLY ' : 'ANNUAL '}
            <div className="view-am"> View</div>
          </button>}

          <div hidden={isGraph} className="sa-table-container mt-0">
            <table className="table table-borderless sa-table-width">
              <thead>
              <tr>
                <th className={"sa-table-head-sticky"}>{t("title.PLOT")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.CURRENT_MONTH")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.PREVIOUS_MONTH")}</th>
              </tr>
              </thead>
              <tbody>{farmDetails.length > 0 && farmDetails.map((data, index) => (
                <tr key={index}>
                  <td className={'sa-table-data'}>{getReferences(data.plotId)}</td>
                  <td className={'sa-table-data'}>{minsToDaysHoursMins(data.durationCurrent)}</td>
                  <td className={'sa-table-data'}>{minsToDaysHoursMins(data.durationPrevious)}</td>

                </tr>
              ))}

              </tbody>
            </table>
            {farmDetails.length === 0 && (
              <div className={"empty-results"}>
                <FeatherIcon icon="info"/>
                <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_REPORTS")}</div>
              </div>)
            }
          </div>
          <div className="table-container mt-0" hidden={!isGraph}>
            {/*  <div hidden={loadGraph}>*/}
            {/*    <div className="sweet-loading">*/}
            {/*      <BeatLoader*/}
            {/*        css={override}*/}
            {/*        size={18}*/}
            {/*        color={"#11bc4d"}*/}
            {/*        loading={true}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*/!*  {isGraph && loadGraph && dataSet.data && (*!/*/}
            {/*    <C3Chart area={{zerobased: false}} padding={{left: 45}} tooltip={dataSet.tooltip} zoom={dataSet.zoom}*/}
            {/*             data={dataSet.data} subchart={{show: true}} onrendered={styleGraph} axis={dataSet.axis}/>*/}
            {/*  )}*/}
            <FarmIrrigationGraph fromDate={fromDate} isPeriod={isPeriod} isGraph={isGraph} annual={annual}
                                 farmReport={true}/>
          </div>
        </div>

      </ToggleLayout>
      <ToggleLayout imgType={"feather"}
                    feather={"alert-triangle"}
                    title={t("title.ALERT_HISTORY")} dropDown={false} visibleToggleIndex={visibleToggleIndex}
                    toggleIndex={2}
                    onVisibleToggleIndexChange={(index) => setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}>
        <FarmAlertHistory/>
      </ToggleLayout>
    </Container>
  )
};


export default FarmReport;

